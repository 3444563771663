import React, { useState, useRef, useEffect } from "react";
import { IDrawnTokenEvent, IRound, IToken } from "../common/interfaces";
import './drawer_visor.css';
import { usePlayer } from "../providers/PlayerProvider";
import WinnerAnimation from "./winner-animation";
import LooserAnimation from "./looser-animation";

interface MyComponentProps {
  tokens: IToken[],
  lastDrawnTokenEvent?: IDrawnTokenEvent;
  currentRound: IRound;
  nextRound: () => void;
}

const DrawerVisor: React.FC<MyComponentProps> = ({tokens, lastDrawnTokenEvent, currentRound, nextRound}) => {

  const [currentRandomToken, setCurrentRandomToken] = useState<IToken | null>(null);
  const shufflerRef = useRef<NodeJS.Timeout | null>(null);
  const [isCardWinner, setIsCardWinner] = useState<boolean>();
  const playerContext = usePlayer();

  const speak = (drawnTokenEvent: IDrawnTokenEvent) => {
    if ( 'speechSynthesis' in window ) {

      const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

      if(lastDrawnToken) {
        var speech = lastDrawnToken.name;

        if(drawnTokenEvent.winners.length > 0) {

          speech += ", y han cantado lotería.";

          const amIWinner = drawnTokenEvent.winners.some((winner) => winner.id === playerContext.player?.id);

          if(drawnTokenEvent.winners.length === 1) {
            if(amIWinner) {
              speech += "Felicidades, has ganado con el cartón número " + drawnTokenEvent.winners[0].cards[0].id;
            } else {
              speech += drawnTokenEvent.winners[0].name + " ha ganado con el cartón número " + drawnTokenEvent.winners[0].cards[0].id;
            }

          } else if(drawnTokenEvent.winners.length > 1) {
            
            if(amIWinner) {
              speech += "Felicidades, has ganado con el cartón número " + drawnTokenEvent.winners[0].cards[0].id + ".";
              speech += "Tambien hay otros " + (drawnTokenEvent.winners.length - 1) + " ganadores.";
            } else {
              speech += "Hay " + drawnTokenEvent.winners.length + " ganadores.";
            }

            for(var i = 0; i < drawnTokenEvent.winners.length - 1; i++) {
              speech += drawnTokenEvent.winners[i].name + " ha ganado con el cartón número " + drawnTokenEvent.winners[i].cards[0].id + ".";
            }
            speech += "y " + drawnTokenEvent.winners.at(-1)?.name + " ha ganado con el cartón número " + drawnTokenEvent.winners.at(-1)?.cards[0].id;
          }
        }
        
        const utterance = new SpeechSynthesisUtterance(speech);
        utterance.lang = 'es-ES';
        window.speechSynthesis.speak(utterance);

      }
     
    }
  };

  const startShuffler = () => {
    if (!shufflerRef.current) {
      shufflerRef.current = setInterval(() => {
        const notDrawnTokens = tokens.filter((token) => !token.drawnDate);
        const randomIndex = Math.floor(Math.random() * notDrawnTokens.length);
        setCurrentRandomToken(notDrawnTokens[randomIndex]);
      }, 100);
    }
  }

  const stopShuffler = () => {
    if (shufflerRef.current) {
      clearInterval(shufflerRef.current);
      shufflerRef.current = null;
      setCurrentRandomToken(null);
    }
  };

  useEffect(() => {
    if (lastDrawnTokenEvent) {
      // Ejecutar startShuffler
      startShuffler();

      // Ejecutar stopShuffler después de 2 segundos
      const timeoutId = setTimeout(() => {
        stopShuffler();

        if(lastDrawnTokenEvent.winners.length > 0) {
          
          setTimeout(() => {
            const IAmWinner = lastDrawnTokenEvent.winners.some((winner) => winner.id === playerContext.player?.id);
            if(IAmWinner) {
              setIsCardWinner(true);
            } else {
              setIsCardWinner(false);
            }
          }, 1000);
        }

      }, 2000);

      speak(lastDrawnTokenEvent!);

      // Limpieza para evitar efectos acumulativos
      return () => clearTimeout(timeoutId);
    }
  }, [lastDrawnTokenEvent]);

  return (
    <div className="flex flex-col">
      {isCardWinner && <WinnerAnimation lastDrawnTokenEvent={lastDrawnTokenEvent!} currentRound={currentRound} nextRound={nextRound} setIsCardWinner={setIsCardWinner}/>}
      {isCardWinner === false && <LooserAnimation lastDrawnTokenEvent={lastDrawnTokenEvent!} tokens={tokens} currentRound={currentRound} nextRound={nextRound} setIsCardWinner={setIsCardWinner}/>}
      {/* Fila superior */}
      <div className="flex w-full">
        {/* Columna 1 */}
        <div className="w-1/2 flex items-center justify-center">
          <img
            src="/guacamayas.png"
            alt="Guacamayas"
            className="max-w-28 object-contain"
          />
        </div>
  
        {/* Columna 2 */}
        <div className="w-1/2 flex items-center justify-center">
          {(currentRandomToken || lastDrawnTokenEvent?.drawnTokens.at(-1)) && (
            <img
              src={`/tokens/${
                currentRandomToken?.name || lastDrawnTokenEvent?.drawnTokens.at(-1)?.name
              }.png`}
              alt="Ficha"
              className="w-20 object-contain"
            />
          )}
        </div>
      </div>
  
      {/* Fila inferior */}
      <div className="flex max-w-[80%] overflow-hidden items-center justify-center mt-4 relative">
        <div className="animate-marquee whitespace-nowrap">
          {lastDrawnTokenEvent?.hots?.map((hot, index) => (
            <span key={index} className="mx-4 text-lg">🌟 {hot.playerName}({hot.cardId})</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DrawerVisor;