import React, { createContext, useContext, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

interface OrganizerContextType {
  login: (email: string, password: string) => void;
  logout: () => void;
}

const OrganizerContext = createContext<OrganizerContextType | undefined>(undefined);

export const OrganizerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const navigate = useNavigate();
   
  const login = (email: string, password: string) => {
    fetch(process.env.REACT_APP_API_URL + "/auth/organizer/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        credentials: "include",
      })
        .then((response) => {
          if (!response.ok) throw new Error("Login failed");
          navigate('/organizer/lotteries', {replace: true});
        })
        .catch((error) => {
          console.error("Error during login:", error);
        });
  };

  const logout = () => {
    fetch(process.env.REACT_APP_API_URL + "/auth/organizer/logout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    }).then((response) => {
        if (response.ok) {
          
          navigate('/organizer/login', {replace: true});
        } 
    });

  };

  return (
    <OrganizerContext.Provider value={{ login, logout }}>
      {children}
    </OrganizerContext.Provider>
  );
};

export const useOrganizer = () => {
  const context = useContext(OrganizerContext);
  if (!context) {
    throw new Error("useOrganizer debe ser usado dentro de un OrganizerProvider");
  }
  return context;
};
