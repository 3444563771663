import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";

interface MyComponentProps {
  role: 'player' | 'organizer';
}

const Auth: React.FC<MyComponentProps> = ({role}) => {
  
 return Cookies.get('connect.sid') ? <Outlet /> : <Navigate to={role === 'player' ? '/player/enter' : '/organizer/login'} replace />;
};

export default Auth;