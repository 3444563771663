import React, { useState } from 'react';
import WinningExampleCard from '../components/winning_example_card';
import { useNavigate } from 'react-router-dom';

interface WinningCombination {
    id: number, 
    name: string,
    combination: number[]
}

interface LotteryRoundDto {
  award: string;
  winningCombination: WinningCombination
}

interface LotteryDto {
  name: string;
  cardsQty: number;
  currency: string;
  cardPrice: number;
  rounds: LotteryRoundDto[];
  cardSerialDraw: boolean;
  cardSerialDrawAward?: string;
  drawDate: string;
}

const winningCombinations = [
  {id: 1, name: "En L", combination: [0,5,10,15,20,21,22,23,24]},
  {id: 2, name: "En X", combination: [0,6,12,18,24,20,16,8,4]},
  {id: 3, name: "En T", combination: [0,1,2,3,4,7,12,17,22]},
  {id: 4, name: "En Cruz", combination: [2,7,12,17,22,10,11,13,14]},
  {id: 99, name: "Cartón Lleno", combination: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]}
];

const currencies = [
  {code: 'VED', name: "Bolívares"},
  {code: 'USD', name: "Dólares"},
  {code: 'COP', name: "Peso Colombianos"},
  {code: 'PEN', name: "Soles"},
  {code: 'EUR', name: "Euros"}
];

const CreateLotteryPage: React.FC = () => {

  const navigate = useNavigate();


    const defaultCombination = winningCombinations[0];

  const [formData, setFormData] = useState<LotteryDto>({
    name: '',
    cardsQty: 5,
    currency: "VED",
    cardPrice: 0.0,
    rounds: [{ award: '', winningCombination: {id: 1, name: "En L", combination: [0,5,10,15,20,21,22,23,24]} }],
    drawDate: '',
    cardSerialDraw: true,
    cardSerialDrawAward: ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleRoundChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const updatedRounds = [...formData.rounds];
    updatedRounds[index] = { ...updatedRounds[index], [name]: value };
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const handleRemoveRound = (index: number) => {
    const updatedRounds = [...formData.rounds];
    updatedRounds.splice(index, 1);
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const handleWinningCombinationChange = (index: number, selectedWinningCombination: WinningCombination) => {
    const updatedRounds = [...formData.rounds];
    updatedRounds[index] = { ...updatedRounds[index], winningCombination: selectedWinningCombination };
    setFormData((prev) => ({ ...prev, rounds: updatedRounds }));
  };

  const addRound = () => {
    setFormData((prev) => ({
      ...prev,
      rounds: [...prev.rounds, { award: '', winningCombination: defaultCombination }],
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + '/lotteries', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include'
      });

      if (response.ok) {
        const result = await response.json();
        navigate('/organizer/lotteries')
      } else {
        console.error('Failed to submit:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='flex items-center justify-center bg-gray-100'>
        <form onSubmit={handleSubmit} className="w-full md:w-1/2 space-y-6 bg-white p-6 shadow-lg rounded-lg">
        <button type="button"onClick={() => navigate('/organizer/lotteries')} className="text-green-500 hover:text-green-700 font-bold">Atras</button>
        <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nombre de la Lotería
            </label>
            <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
        </div>

        <div>
            <label htmlFor="cardsQty" className="block text-sm font-medium text-gray-700">
            Cantidad de Cartas
            </label>
            <input
            type="number"
            id="cardsQty"
            name="cardsQty"
            value={formData.cardsQty}
            min={5}
            max={3000}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
        </div>

        <div>
            <label htmlFor="cardPrice" className="block text-sm font-medium text-gray-700">
            Precio por Carta
            </label>
            <input
            type="number"
            id="cardPrice"
            name="cardPrice"
            value={formData.cardPrice}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
            <label htmlFor={`currency`} className="block text-sm font-medium text-gray-700">
            Divisa
            </label>
            <select
            id={`currency`}
            name="currency"
            value={formData.currency}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            >
            <option value="">Seleccione una divisa</option>
            {currencies.map((currency) => (
            <option key={currency.code} value={currency.code}>
              {currency.name}
            </option>
            ))}
            </select>
        </div>  

        <div>
            <label htmlFor="drawDate" className="block text-sm font-medium text-gray-700">
            Fecha del Sorteo
            </label>
            <input
            type="datetime-local"
            id="drawDate"
            name="drawDate"
            value={formData.drawDate}
            onChange={handleChange}
            required
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />
        </div>

        <div>
            <label htmlFor="cardSerialDraw" className="block text-sm font-medium text-gray-700">
            Sorteo del Serial del Carton
            </label>
            <input
            type="checkbox"
            id="cardSerialDraw"
            name="cardSerialDraw"
            checked={formData.cardSerialDraw}
            onChange={(e) => setFormData((prev) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }))}
            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
            />

            {formData.cardSerialDraw && <div>
              <label htmlFor="cardSerialDrawAward" className="block text-sm font-medium text-gray-700">
              Premio
              </label>
              <input
              type="text"
              id="cardSerialDrawAward"
              name="cardSerialDrawAward"
              value={formData.cardSerialDrawAward}
              onChange={handleChange}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
            </div>} 
        </div>

        <div>
            <h3 className="text-lg font-medium">Rondas</h3>
            {formData.rounds.map((round, index) => (
                
            <div key={index} className="mt-4 space-y-2 border p-2 rounded-md">
                <div>
                    <button
                        type="button"
                        onClick={() => handleRemoveRound(index)}
                        className="text-red-500 hover:text-red-700 font-bold"
                    >
                        X
                    </button>
                    <label htmlFor={`roundName-${index}`} className="block text-sm font-medium text-gray-700">
                        Premio de la ronda
                    </label>
                    <input
                        type="text"
                        id={`roundAward-${index}`}
                        name="award"
                        value={round.award}
                        onChange={(e) => handleRoundChange(index, e)}
                        required
                        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                    />
                </div>
                <div>
                    <div key={index} className="mb-4">
                        <label htmlFor={`winningCombination-${index}`} className="block text-sm font-medium text-gray-700">
                            Select Winning Combination
                        </label>
                        <select
                            id={`winningCombination-${index}`}
                            name="winningCombination"
                            value={round.winningCombination.id}
                            onChange={(e) => {
                                const selectedId = Number(e.target.value);
                                const combination = winningCombinations.find((comb) => comb.id === selectedId);
                                if (combination) handleWinningCombinationChange(index, combination);
                              }}
                            required
                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
                        >
                            <option value="">Select a combination</option>
                            {winningCombinations.map((combination) => (
                            <option key={combination.id} value={combination.id}>
                                {combination.name}
                            </option>
                            ))}
                        </select>
                        <WinningExampleCard winningCombination={round.winningCombination.combination}/>
                    </div>
                </div>
            </div>
            
            ))}
            <button
            type="button"
            onClick={addRound}
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
            >
            Añadir Ronda
            </button>
            
        </div>

        <button
            type="submit"
            className="w-full px-4 py-2 bg-green-500 text-white rounded-md shadow hover:bg-green-600"
        >
            Crear Lotería
        </button>
        </form>
    </div>

  );
};

export default CreateLotteryPage;