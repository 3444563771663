import React from 'react';
import Lottie from 'lottie-react';
import winnerAnimation from '../lottie-animations/winner.json';
import { IDrawnTokenEvent, IRound } from '../common/interfaces';

interface MyComponentProps {
    lastDrawnTokenEvent: IDrawnTokenEvent;
    currentRound: IRound;
    nextRound: () => void;
    setIsCardWinner: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const WinnerAnimation: React.FC<MyComponentProps> = ({ lastDrawnTokenEvent, nextRound, currentRound, setIsCardWinner }) => {

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black z-50">
      {/* Botón de cerrar */}
      <button
        onClick={() => {nextRound(); setIsCardWinner(undefined)}}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Cerrar
      </button>

      {/* Texto centrado arriba de la animación */}
      <div className="text-center mt-20">
        <h1 className="text-white text-4xl font-bold">
          Has Ganado
        </h1>
        <h2 className="text-white text-3xl font-bold">
          {currentRound.award}
        </h2>
        {lastDrawnTokenEvent.winners.length > 1 ? <p className="text-white text-lg">(y {lastDrawnTokenEvent.winners.length - 1} mas)</p> : '' }
      </div>

      {/* Animación Lottie */}
      <Lottie
        animationData={winnerAnimation}
        loop={true}
        className="w-full h-full max-h-[70%] object-contain"
      />
      <p className="mt-2 text-white text-lg">Contacta al organizador...</p>
    </div>
  );
};

export default WinnerAnimation;
