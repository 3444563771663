import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePlayer } from "../providers/PlayerProvider";
import Cookies from "js-cookie";

const LotteryEnterPage: React.FC = () => {
  const [pase, setPase] = useState<string>("");
  const playerContext = usePlayer();
  const navigate = useNavigate();

  useEffect(() => {
    if(Cookies.get('connect.sid')) {
      navigate('/player/lotteries/table');
    }
  });

    // Función para manejar el submit del formulario
    const handleEnter = (e: React.FormEvent) => {
      e.preventDefault();
      if (pase) {
        playerContext.enter(pase);
      } else {
        alert("Por favor ingresa un pase.");
      }
    };
  
    return (
<div className="max-w-sm mx-auto p-6 bg-white rounded-lg shadow-lg">
  <h2 className="text-2xl font-bold text-center mb-6">Login de Pase</h2>
  
  <form onSubmit={handleEnter} className="space-y-4">
    {/* Campo de Pase */}
    <div className="mb-4">
      <label htmlFor="pase" className="block text-sm font-semibold text-gray-700 mb-2">Pase:</label>
      <input
        type="text"
        id="pase"
        name="pase"
        value={pase}
        onChange={(e) => setPase(e.target.value)}
        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>

    {/* Botón de Entrada */}
    <button
      type="submit"
      className="w-full p-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
    >
      Entrar
    </button>
  </form>
</div>

    );
};

export default LotteryEnterPage;
