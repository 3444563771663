import React, { useEffect, useRef, useState } from "react";
import Card from "../components/card";
import { ICard, IDrawnTokenEvent, IPlayerGame, IRound, ISerialWinnerPlayer, IToken } from "../common/interfaces";
import ChatWindow from "../components/chat_window";
import socket from "../socket";
import DrawerVisor from "../components/drawer_visor";
import { FaInfoCircle, FaEnvelope } from "react-icons/fa";
import Loading from "../components/loading";
import { preloadTokenImages } from "../common/functions";
import SerialWinnerAnimation from "../components/serial-winner-animation";

const PlayerPage: React.FC = () => {
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [isLeftVisible, setIsLeftVisible] = useState(false);
  const [isRightVisible, setIsRightVisible] = useState(false);
  const [playerGame, setPlayerGame] = useState<IPlayerGame>();
  const [currentRound, setCurrentRound] = useState<IRound>();
  const currentRoundRef = useRef<IRound | undefined>();
  const [serialWinnerPlayer, setSerialWinnerPlayer] = useState<ISerialWinnerPlayer>();
  const [loading, setLoading] = useState(true);

  const [lastDrawnTokenEvent, setLastDrawnTokenEvent] = useState<IDrawnTokenEvent>();
  
  const openLeftColumn = () => {
    setIsLeftVisible(true);
    window.history.pushState(null, "", window.location.href);
  };

  const openRightColumn = () => {
    setIsRightVisible(true);
    window.history.pushState(null, "", window.location.href);
  };  

  const fetchAllTokens = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/tokens/all"); 
    if (!response.ok) {
      throw new Error("Error en la solicitud");
    }

    const tokens: IToken[] = await response.json();  
    
    return tokens;
  };  
 
  const fetchGame = async () => {

    const response = await fetch(process.env.REACT_APP_API_URL + "/lotteries/mygame", {
      method: "GET",
      credentials: "include",
    });
    if (!response.ok) throw new Error();

    const playerGame: IPlayerGame = await response.json();

    return playerGame;

  };

  const nextRound = () => {
    const rounds = playerGame?.lottery.rounds!;
    const currentIndex = rounds.findIndex(round => round.id === currentRound?.id);
  
    // Verificar si existe un siguiente round
    if (currentIndex !== -1 && currentIndex < rounds.length - 1) {

      setPlayerGame((prevPlayerGame) => {
        if(prevPlayerGame) {
          const updatedRounds = prevPlayerGame.lottery.rounds.map((round) =>
            round.id === lastDrawnTokenEvent?.roundId
              ? { ...round, victories: lastDrawnTokenEvent.winners} // Modifica las propiedades que necesites
              : round // Mantén los demás sin cambios
          );

          return {
            ...prevPlayerGame,
            lottery: {
              ...prevPlayerGame.lottery,
              rounds: updatedRounds, // Reemplaza los rounds con los actualizados
            },
          };

        } 

      });

      const newCurrentRound = rounds[currentIndex + 1];
      setCurrentRound(newCurrentRound);
    } else {
      setCurrentRound(undefined);
    }

    setLastDrawnTokenEvent(undefined);

    // Recogemos todas las fichas asignandole drawnDate: undefined.
    setTokens((prevTokens) => {
      const updatedTokens: IToken[] = prevTokens.map(token => ({
        ...token,
        drawnDate: undefined 
      }));

      return updatedTokens;
    });
  
  }

  useEffect(() => { 

    const onTokenDrawn = async (drawnTokenEvent: IDrawnTokenEvent) => {

      const drawnTokensMap = new Map(drawnTokenEvent.drawnTokens.map(token => [token.id, token.drawnDate]));

      if(drawnTokenEvent.roundId === currentRoundRef.current?.id) {
        setTokens((prevTokens) => {
          const updatedTokens: IToken[] = prevTokens.map(token => ({
            ...token,
            drawnDate: drawnTokensMap.get(token.id) || token.drawnDate, 
          }));
  
          return updatedTokens;
        });
  
        setLastDrawnTokenEvent(drawnTokenEvent);
      }

    }

    const onSerialWinner = async (serialWinnerPlayer: ISerialWinnerPlayer) => {
      setSerialWinnerPlayer(serialWinnerPlayer) 
    }    
 
    const mountGame = async () => {
      const _tokens = await fetchAllTokens();
      await preloadTokenImages(_tokens);

      const _playerGame = await fetchGame();
      
      setPlayerGame(_playerGame);  

      // Cargamos card.tokens desde 'tokens' ya que solo tenemos card.tokenIds
      _playerGame.myCards = _playerGame.myCards.map((card) => {
        card.tokens = card.tokenIds.map((tokenId) => (_tokens.find((token) => token.id === tokenId)!));
        return card;
      });

      if(_playerGame.lottery.currentRoundId) {
        const _currentRound = _playerGame.lottery.rounds.find((round) => round.id === _playerGame.lottery.currentRoundId)!;
        setCurrentRound(_currentRound);

        const drawnTokensMap = new Map(_currentRound.drawnTokens.map(token => [token.id, token.drawnDate]));

        setTokens(() => {
          const updatedTokens: IToken[] = _tokens.map(token => ({
            ...token,
            drawnDate: drawnTokensMap.get(token.id) || token.drawnDate, 
          }));
  
          return updatedTokens;
        });
      } else {
        setTokens(_tokens);
      }

      if(_playerGame.lottery.live) {

        socket.on("tokenDrawn", onTokenDrawn);
        socket.on("serialWinner", onSerialWinner);
    
        socket.io.opts.query = { role: "player"}
        socket.connect();
      }


      setLoading(false);
    };

    mountGame();

    return () => {
      socket.off("tokenDrawn", onTokenDrawn);
      socket.off("serialWinner", onSerialWinner);
      socket.disconnect();

    }

   }, []);

   useEffect(() => {
    // Agregar una entrada inicial al historial
    window.history.pushState(null, "", window.location.href);

    const handlePopState = () => {
      // Si no hay columnas abiertas, rehacer el estado actual
      if (!isLeftVisible && !isRightVisible) {
        window.history.pushState(null, "", window.location.href);
        return;
      }

      // Si hay una columna abierta, replegarla
      if (isLeftVisible) {
        setIsLeftVisible(false);
      } else if (isRightVisible) {
        setIsRightVisible(false);
      }
    };

    // Escucha el evento de retroceso del historial
    window.addEventListener("popstate", handlePopState);

    // Limpieza al desmontar el componente
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isLeftVisible, isRightVisible]);

  useEffect(() => {
    currentRoundRef.current = currentRound;
  }, [currentRound]);

  if (loading) {
    return <Loading/>
  }

  return (
<div className="flex flex-col h-screen">
  {serialWinnerPlayer && (
    <SerialWinnerAnimation serialWinnerPlayer={serialWinnerPlayer} tokens={tokens} />
  )}

  {/* Botones flotantes para móviles */}
  {(!isLeftVisible && !isRightVisible) && (
    <div className="lg:hidden fixed bottom-4 right-4 z-30 flex flex-col space-y-2">
      <button
        className="w-12 h-12 bg-customBrown-300 text-white rounded-full shadow-lg flex items-center justify-center"
        onClick={openLeftColumn}
      >
        <FaInfoCircle className="w-6 h-6" />
      </button>
      <button
        className="w-12 h-12 bg-white text-customBrown-300 rounded-full shadow-lg flex items-center justify-center"
        onClick={openRightColumn}
      >
        <FaEnvelope className="w-6 h-6" />
      </button>
    </div>
  )}

  {/* Estructura de columnas */}
  <div className="flex flex-1 flex-row">
    {/* Columna izquierda */}
    <div
      className={`bg-gray-300 w-full lg:w-1/4 h-full fixed top-0 left-0 z-20 transition-transform duration-300 ${
        isLeftVisible || "lg:translate-x-0 -translate-x-full"
      }`}
    >
      <button
        onClick={() => setIsLeftVisible(false)}
        className="absolute top-2 right-2 w-8 h-8 bg-red-500 text-white rounded-full lg:hidden"
      >
        ×
      </button>
      <div className="p-4 overflow-y-auto h-full">
        <h2 className="text-xl font-bold mb-4">Información</h2>
        <div className="space-y-2">
          <p><strong>Nombre:</strong> {playerGame?.lottery.name}</p>
          <p><strong>Precio del Cartón:</strong> {playerGame?.lottery.cardPrice} {playerGame?.lottery.currency}</p>
          <p><strong>Fecha del Sorteo:</strong> {playerGame?.lottery.drawDate ? new Date(playerGame?.lottery.drawDate).toLocaleDateString() : "N/A"}</p>
          <p><strong>Fecha de Inicio:</strong> {playerGame?.lottery.startDate ? new Date(playerGame?.lottery.startDate).toLocaleDateString() : "N/A"}</p>
          <p><strong>Fecha de Fin:</strong> {playerGame?.lottery.endDate ? new Date(playerGame.lottery.endDate).toLocaleDateString() : "N/A"}</p>
          <h3 className="font-bold mt-4">Organizador</h3>
          <p><strong>Nombre:</strong> {playerGame?.lottery.organizer.name}</p>
          <p><strong>Email:</strong> {playerGame?.lottery.organizer.email}</p>
          <p><strong>Telefono:</strong> {playerGame?.lottery.organizer.phone}</p>
          {playerGame?.lottery.cardSerialDraw &&
          <div>
            <p><strong>Premio Serial Ganador:</strong> {playerGame?.lottery.cardSerialDrawAward}</p>
            {
            playerGame?.lottery.cardSerialWinner && 
            <p><strong>Ganador:</strong> {playerGame?.lottery.cardSerialWinner.card.id} ({playerGame?.lottery.cardSerialWinner.name})</p>
            }
          </div>
          }
          <h3 className="font-bold mt-4">Rondas</h3>
          <ul className="space-y-4">
            {playerGame?.lottery.rounds.map((round, index) => (
              <li key={round.id} className={`border p-2 rounded-md  ${round.id === currentRound?.id ? 'bg-yellow-300' : 'bg-gray-100'}`}>
                <p><strong>Ronda {index + 1}</strong></p>
                <p><strong>Premio:</strong> {round.award}</p>
                <p><strong>Combinación Ganadora:</strong> {round.winningCombination.name}</p>
                <p><strong>Victorias:</strong></p>
                <ul className="ml-4 list-disc">
                  {round.victories.map((victory, idx) => (
                    <li key={idx}>
                      <p>{victory.name}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>

    {/* Contenido central */}
    <div className="w-full bg-customYellowm-200 relative lg:w-1/2 lg:ml-[25%]">
      {/* Fila superior fija dentro de la columna central */}
      <div
        className="sticky top-0 w-full bg-customBrown-700 text-white p-4 mb-4 z-10"
        style={{
          backgroundImage: "url('/guamacayas.png')",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          border: "4px solid transparent",
          boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.5)",
        }}
      >
        <DrawerVisor
          tokens={tokens}
          lastDrawnTokenEvent={lastDrawnTokenEvent}
          currentRound={currentRound!}
          nextRound={nextRound}
        />
      </div>

      {/* Contenido principal */}
      <div className="mt-4 flex justify-center items-center flex-wrap gap-4">
        {playerGame?.myCards.map((card) => (
          <Card
            key={card.id}
            newCard={card}
            tokens={tokens}
            winningCombination={currentRound?.winningCombination.combination}
            showSerial={true}
          />
        ))}
      </div>
    </div>

    {/* Columna derecha */}
    <div
      className={`bg-gray-300/50 w-full lg:w-1/4 h-full fixed top-0 right-0 z-20 transition-transform duration-300 ${
        isRightVisible || "lg:translate-x-0 translate-x-full"
      }`}
    >
      <button
        onClick={() => setIsRightVisible(false)}
        className="absolute top-2 right-2 w-8 h-8 bg-red-500 text-white rounded-full lg:hidden"
      >
        ×
      </button>
      <ChatWindow isOrganizer={false} />
    </div>
  </div>
</div>



  );
};

export default PlayerPage;