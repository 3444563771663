import React, { useState, useRef, useEffect } from "react";
import { IDrawnTokenEvent, IToken } from "../common/interfaces";
import { Socket } from "socket.io-client";

interface MyComponentProps {
  socket: Socket;
  tokens: IToken[],
  setTokens: React.Dispatch<React.SetStateAction<IToken[]>>;
}

const Drawer: React.FC<MyComponentProps> = ({setTokens, socket, tokens}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [lastDrawnToken, setLastDrawnToken] = useState<IToken | null>(null); 
  const [currentRandomToken, setCurrentRandomToken] = useState<IToken | null>(null);
  const shufflerRef = useRef<NodeJS.Timeout | null>(null);

  const speak = (drawnTokenEvent: IDrawnTokenEvent) => {
    if ( 'speechSynthesis' in window ) {

      const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

      if(lastDrawnToken) {
        var speech = lastDrawnToken.name;

        if(drawnTokenEvent.winners.length > 0) {

          speech += ", y han cantado lotería.";

          if(drawnTokenEvent.winners.length === 1) {
            speech += drawnTokenEvent.winners[0].name + " ha ganado con el cartón número " + drawnTokenEvent.winners[0].cards[0].id;

          } else if(drawnTokenEvent.winners.length > 1) {
            
            speech += "Hay " + drawnTokenEvent.winners.length + " ganadores.";

            for(var i = 0; i < drawnTokenEvent.winners.length - 1; i++) {
              speech += drawnTokenEvent.winners[i].name + " ha ganado con el cartón número " + drawnTokenEvent.winners[i].cards[0].id + ".";
            }
            speech += "y " + drawnTokenEvent.winners.at(-1)?.name + " ha ganado con el cartón número " + drawnTokenEvent.winners.at(-1)?.cards[0].id;
          }
        }
        
        const utterance = new SpeechSynthesisUtterance(speech);
        utterance.lang = 'es-ES';
        window.speechSynthesis.speak(utterance);

      }
     
    }
  };  

  const startShuffler = () => {
    if (!shufflerRef.current) {
      shufflerRef.current = setInterval(() => {
        const notDrawnTokens = tokens.filter((token) => !token.drawnDate);
        const randomIndex = Math.floor(Math.random() * notDrawnTokens.length);
        setCurrentRandomToken(notDrawnTokens[randomIndex]);
      }, 100);
    }
  }

  const stopShuffler = () => {
    if (shufflerRef.current) {
      clearInterval(shufflerRef.current);
      shufflerRef.current = null;
      setCurrentRandomToken(null);
    }
  };

  useEffect(() => {
    const onTokenDrawn = (drawnTokenEvent: IDrawnTokenEvent) => {

      const drawnTokensMap = new Map(drawnTokenEvent.drawnTokens.map(token => [token.id, token.drawnDate]));

      setTokens((prevTokens) => {
        const updatedTokens: IToken[] = prevTokens.map(token => ({
          ...token,
          drawnDate: drawnTokensMap.get(token.id) || token.drawnDate, 
        }));

        return updatedTokens;
      });

      const lastDrawnToken = drawnTokenEvent.drawnTokens.at(-1);

      if(lastDrawnToken) {
        setLastDrawnToken(lastDrawnToken);
        setIsDrawing(false);
        stopShuffler();
        speak(drawnTokenEvent);
      }

    }

    socket.on("tokenDrawn", onTokenDrawn);

    return () => {
      socket.off("tokenDrawn", onTokenDrawn);
    }

  }, []);

  const handleDraw = () => {
    if(!isDrawing) {
      setIsDrawing(true);
      startShuffler();
      socket.emit("draw");
    }

  };

  const handleDrawSerialWinner = () => {
    socket.emit("drawserial");

  };  

  return (
    <div>
      <div className="w-full flex h-32">
        {/* Columna 1 */}
        <div className="w-1/2 flex items-center justify-center cursor-grab" onClick={handleDraw} onTouchStart={handleDraw}>
          <img src="/bag.png" alt="Bolsa de fichas" className={`max-w-full max-h-full object-contain ${!isDrawing ? 'animate-growShrink' : ''}`} />
        </div>
        
        {/* Columna 2 */}
        <div className="w-1/2 flex items-center justify-center">
          {(currentRandomToken || lastDrawnToken) && <img src={`/tokens/${currentRandomToken?.name || lastDrawnToken?.name}.png` } alt="Ficha" className="w-20 object-contain" />}
        </div>
      </div>
      <button className="mt-4" onClick={handleDrawSerialWinner}>Sortear Serial</button>
    </div>

  );
};

export default Drawer;