import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ISellCardError, ISellCards } from '../common/interfaces';
import { send } from 'process';

interface MyComponentProps {
  lotteryId: number,
}


const CartonIdInput: React.FC<MyComponentProps> = ({lotteryId}) => {

  const handleShare = async (lotteryName: string, pass: string) => {

    if (navigator.share) { // Verifica si la API de Web Share está disponible
      try {
        await navigator.share({
          title: `Loteria ${lotteryName}`,
          text: `Gracias por participar. Tu pase es: ${pass} y puedes acceder desde:`,
          url: 'https://guacamayalote.online/player/enter', // URL que se va a compartir
        });
        console.log('¡Enlace compartido exitosamente!');
      } catch (error) {
        console.error('Error al compartir:', error);
      }
    } else {
      alert('La funcionalidad de compartir no está disponible en este dispositivo.');
    }
  };

  const [sending, setSending] = useState(false);
  const [pass, setPass] = useState('');

  const [formData, setFormData] = useState({
    player: {
      name: '',
      email: '',
      phone: ''
    },
    cardIds: []
  } as ISellCards);

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
    cardIds: 'Introduzca al menos 1 Serial de Carton.'
  });

  const [errorsFromServer, setErrorsFromServer] = useState<ISellCardError[]>([]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      player: {
        ...prev.player,
        [name]: value,
      },
    }));

    validateField(name, value);
  };

  const validateField = (name: string, value: string | number[]) => {
    let errorMessage = '';

    if(typeof value === 'string') {
      switch (name) {
        case 'name':
          if (value.trim().length < 4) {
            errorMessage = 'El nombre debe tener al menos 4 caracteres.';
          }
          break;
        case 'email':
          if (value !== '' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
            errorMessage = 'El correo electrónico no es válido.';
          }
          break;
        case 'phone':
          if (value !== '' && !/^\d{10}$/.test(value)) {
            errorMessage = 'El número de teléfono debe tener 10 dígitos.';
          }
          break;
        default:
          break;
      }
    } else {
      if(name === 'cardIds' && value.length === 0) {
        errorMessage = 'Debe introducir al menos un Serial de Carton...';
      }
    }

    setPass('');
    setErrors((prev) => ({ ...prev, [name]: errorMessage }));
  };
  const [inputValue, setInputValue] = useState('');

  const handleAddCartonId = () => {

    if (inputValue.trim()) {
      
      const newId = parseInt(inputValue.trim(), 10);

      if (!isNaN(newId) && !formData.cardIds.includes(newId)) {
        const updatedCardsIds = [...formData.cardIds, newId];
        setFormData((prevFormData) => ({
          ...prevFormData,
          cardIds: updatedCardsIds
        }));
        validateField('cardIds', updatedCardsIds);
        setInputValue('');
      }
      
    }
  };

  const handleRemoveCartonId = (id: number) => {
    const updatedCardsIds = formData.cardIds.filter(cartonId => cartonId !== id);
    setFormData((prevFormData) => ({
      ...prevFormData,
      cardIds: updatedCardsIds
    }));

    validateField('cardIds', updatedCardsIds);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    var isValid = Object.values(errors).every((error) => error === '');

    if (isValid) {
      setErrorsFromServer([]);
      try {
        setSending(true);
        const response = await fetch(process.env.REACT_APP_API_URL + `/lotteries/${lotteryId}/sellCards`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
          credentials: 'include'
        });

        if (response.ok) {
          const {pass} = await response.json();
          setPass(pass);
          setFormData({
            player: {
              name: '',
              email: '',
              phone: ''
            },
            cardIds: []
          } as ISellCards);
        } else {
          const { message: errors }: { message: ISellCardError[] } = await response.json();
          setErrorsFromServer(errors);

        }
      } catch (error) {
        console.error('Error:', error);  
      } finally {
        setSending(false);
      } 
    }
  }; 

  return (
    <div>
                    <button
   
                className="ml-2 bg-blue-200 hover:bg-blue-300 rounded-full w-5 h-5 flex items-center justify-center text-blue-800"
              >
                Compartir
              </button>
<form onSubmit={handleSubmit} className="p-4 space-y-4 bg-gray-100 rounded-md">

<div className="space-y-4">
  <div className="border rounded p-3">
    <label htmlFor="cartonIds" className="block text-sm font-medium text-gray-700 mb-2">
      Serial del carton
    </label>
    <input
      type="text"
      id="cartonIds"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
          handleAddCartonId();
        }
      }}
      maxLength={5}
      className="w-full border border-gray-300 rounded px-3 py-2"
      disabled={sending}
    />
    <button
    type="button"
    onClick={handleAddCartonId}
    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
    disabled={sending}
    >
      Anadir
    </button>
    {errors.cardIds && <p className="text-red-500 text-sm">{errors.cardIds}</p>}
  

  <div className="flex flex-wrap gap-2 my-4">
    {formData.cardIds.map((id) => (
      <span
        key={id}
        className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full"
      >
        {id}
        <button
          onClick={() => handleRemoveCartonId(id)}
          className="ml-2 bg-blue-200 hover:bg-blue-300 rounded-full w-5 h-5 flex items-center justify-center text-blue-800"
        >
          &times;
        </button>
      </span>
    ))}
  </div>
  <div>
    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
      Nombre
    </label>
    <input
      type="text"
      id="name"
      name="name"
      value={formData.player.name}
      onChange={handleChange}
      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
      disabled={sending}
    />
    {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
  </div>

  <div>
    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
      Correo Electrónico
    </label>
    <input
      type="email"
      id="email"
      name="email"
      value={formData.player.email}
      onChange={handleChange}
      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
      disabled={sending}
    />
    {errors.email && formData.player.email && <p className="text-red-500 text-sm">{errors.email}</p>}
  </div>

  <div>
    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
      Teléfono
    </label>
    <input
      type="text"
      id="phone"
      name="phone"
      value={formData.player.phone}
      onChange={handleChange}
      className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
      disabled={sending}
    />
    {errors.phone && formData.player.phone && <p className="text-red-500 text-sm">{errors.phone}</p>}
  </div>

  <button
    type="submit"
    className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 my-4"
    disabled={sending || formData.player.name === ''}
  >
    Enviar
  </button>    
  {pass && <p className="text-green-500 text-sm">Cartones vendidos con exito. El pase del jugador es: {pass}</p>}
  {errorsFromServer.map((error, index) => (
    <p key={index} className="text-red-500 text-sm">Carton Serial {error.cardId} {error.message}</p>
  ))}
</div>
</div>
</form>
    </div>


  );
};

export default CartonIdInput;
