import React, { useState, useEffect } from 'react';
import { ILottery } from '../common/interfaces';
import { useNavigate } from 'react-router-dom';
import { useOrganizer } from '../providers/OrganizerProvider';

interface PaginatedResponse {
  lotteries: ILottery[];
  totalPages: number;
}

const LotteriesPage: React.FC = () => {
  const [lotteries, setLotteries] = useState<ILottery[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const organizerContext = useOrganizer();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLotteries = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/lotteries?page=${currentPage}`,
          {credentials: 'include'}
        );
        const data: PaginatedResponse = await response.json();
        setLotteries(data.lotteries);
        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching lotteries:', error);
      }
    };

    fetchLotteries();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };


  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-center mb-6">Mis Loterías</h1>
      <div className="space-y-4">
      <button type="button"onClick={() => organizerContext.logout()} className="text-red-500 hover:text-red-700 font-bold">Salir</button>
      <button type="button"onClick={() => navigate('/organizer/lotteries/create')} className="text-green-500 hover:text-green-700 font-bold">Crear loteria</button>
        {lotteries.map((lottery) => (
          <div key={lottery.id}>
            <div className="p-4 bg-white rounded shadow">
              <h2 className="text-xl font-semibold">{lottery.name}</h2>
              <p className="text-gray-600">Cartones Vendidos: {lottery.soldCardsQty} / {lottery.cardsQty}</p>
              <p className="text-gray-600">Rondas totales: {lottery.rounds.length}</p>
              <p className="text-gray-600">Pago: {lottery.paid ? "Pago recibido" : "Pago no recibido"}</p>
              {lottery.rounds.map((round) => (
                <div key={round.id}>
                  <p className="text-gray-600">Combinacion Ganadora: {round.winningCombination.name}</p>
                  <p className="text-gray-600">Premio: {round.award}</p>
                </div>       
              ))}
              <p className="text-gray-600">Fecha de sorteo: {lottery.drawDate.toLocaleString('en-US', { 
                month: 'numeric', 
                day: 'numeric', 
                year: 'numeric', 
                hour: 'numeric', 
                minute: '2-digit', 
                second: '2-digit', 
                hour12: true 
              })}
              </p>
            </div>
            <button type="button" onClick={() => navigate(`/organizer/lottery/${lottery.id}/sellcards`)} className="text-green-500 hover:text-green-700 font-bold m-5">Vender Cartones</button>
            <a target='_blank' href={`/lotteries/${lottery.id}`} className="text-green-500 hover:text-green-700 font-bold m-5">Mostrador</a>
            <button type="button" onClick={() => navigate('../lottery/' + lottery.id + "/stage")} className="text-green-500 hover:text-green-700 font-bold m-5">Ir</button>
          </div>
        ))}
      </div>

      <div className="flex justify-center items-center space-x-2 mt-6">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50"
        >
          Anterior
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 rounded ${
              currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400'
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default LotteriesPage;
