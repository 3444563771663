import React, { useEffect, useState } from "react";
import { ICard, IToken } from "../common/interfaces";

interface MyComponentProps {
  newCard: ICard;
  tokens?: IToken[];
  winningCombination?: number[];
  showSerial: boolean
}

const Card: React.FC<MyComponentProps> = ({ newCard, tokens, winningCombination, showSerial }) => {

  const [card, setCard] = useState(newCard);

  useEffect(() => {
    if(tokens) {
      setCard((prevCard) => ({
        ...prevCard,
        tokens: prevCard.tokens.map((tokenA) => {
          const tokenB = tokens.find((tokenB) => tokenB.id === tokenA.id);
          return {
            ...tokenA,
            drawnDate: tokenB ? tokenB.drawnDate : undefined,
          };
        }),
      }));
    }

  }, [tokens]);

  const rows = [];
  for (let i = 0; i < card.tokens.length; i += 5) {
    rows.push(card.tokens.slice(i, i + 5));
  }
  
  return (
<div className="flex flex-col items-center">
  {/* Serial centrado y en negritas */}
  {showSerial &&   
  <div className="text-center">
    <span className="text-gray-700 font-bold text-sm">Serial: {card.id}</span>
  </div>
  }

  {/* Cartón */}
  <div className="flex-none w-[250px] lg:w-[350px] grid place-items-center">
    <div className="grid grid-cols-5 gap-1 p-1 bg-customBrown-500 rounded-lg shadow-md border-gray-200">
      {rows.flat().map((token, index) => (
        <div
          key={index}
          className={`relative flex flex-col items-center justify-center border p-2 border-gray-400 rounded-lg ${
            winningCombination?.includes(index) || !winningCombination
              ? 'bg-white'
              : 'bg-neutral-600'
          }`}
        >
          <img
            src={`/tokens/${token.name}.png`}
            alt={`Ficha ${token.name}`}
            className="w-14"
          />
          {token.drawnDate && (
            <img
              src={`/beans/corn.png`}
              className="absolute h-[80%]"
              alt="annotator"
            />
          )}
        </div>
      ))}
    </div>
  </div>
</div>

  );
  
};

export default Card;