import React, { useEffect, useState } from "react";
import { ILottery, IRound, IToken } from "../common/interfaces";
import { useNavigate, useParams } from "react-router-dom";
import Drawer from "../components/drawer";
import MasterCard from "../components/mastercard";
import ChatWindow from "../components/chat_window";
import socket from "../socket";
import { preloadTokenImages } from "../common/functions";
import Loading from "../components/loading";


const StagePage: React.FC = () => {
  
  const { lotteryId } = useParams();
  const [tokens, setTokens] = useState<IToken[]>([]);
  const [lottery, setLottery] = useState<ILottery>();
  const [currentRound, setCurrentRound] = useState<IRound>();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const fetchAllTokens = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/tokens/all"); 
    if (!response.ok) {
      throw new Error("Error en la solicitud");
    }

    const tokens: IToken[] = await response.json();  
    
    return tokens;
  }; 

  useEffect(() => { 

    const fetchLottery = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + "/lotteries/" + lotteryId);
      if (!response.ok) throw new Error("Enter failed");
  
      const lottery: ILottery = await response.json();
      return lottery;
    }    

    const mountLottery = async () => {

      const lottery: ILottery = await fetchLottery();
      setLottery(lottery);
      const _tokens = await fetchAllTokens();
      
      if(lottery.currentRoundId) {
        const currentRound = lottery.rounds.find((round) => round.id === lottery.currentRoundId)!;
        const drawnTokensMap = new Map(currentRound.drawnTokens.map(token => [token.id, token.drawnDate]));

        await preloadTokenImages(_tokens);
  
        setTokens(() => {
          const updatedTokens: IToken[] = _tokens.map(token => ({
            ...token,
            drawnDate: drawnTokensMap.get(token.id) || token.drawnDate, 
          }));
          return updatedTokens;
        });
      } else {
        setTokens(_tokens);
      }
  
      if(lottery.live) {
        socket.io.opts.query = { role: "organizer", lotteryId: lottery.id}
        socket.connect();
      }
      setLoading(false);
    };       

    mountLottery();

    return () => {

      if(socket.connected) {
        socket.disconnect();
      }
    }

  }, [lotteryId]);

  if (loading) {
    return <Loading/>
  }
   
  return (
    <div className="max-w-full w-full grid lg:grid-cols-[2fr_1fr] sm:grid-cols-1 gap-1">
      {/* Columna izquierda */}
      <div className="col-span-1 flex flex-wrap items-center justify-center p-4 border border-gray-300 rounded-lg bg-gray-100 gap-4">
        <button
          type="button"
          onClick={() => navigate('/organizer/lotteries')}
          className="text-green-500 hover:text-green-700 font-bold"
        >
          Atras
        </button>
        <MasterCard tokens={tokens} />
      </div>

      {/* Columna derecha */}
      <div
        className={`fixed top-0 right-0 bg-gray-100 border-l border-gray-300 shadow-lg transform transition-transform duration-300 ease-in-out ${
          isDrawerOpen ? 'translate-x-0' : 'translate-x-full'
        } lg:relative lg:translate-x-0 lg:flex lg:flex-col p-4 rounded-lg`}
      >
        {/* Botón para abrir/cerrar el menú */}
        <button
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          className="sm:block lg:hidden absolute top-1/2 -left-6 transform -translate-y-1/2 w-12 h-12 rounded-full bg-gray-300 flex items-center justify-center shadow-lg"
        >
          <span
            className={`block w-6 h-6 border-t-2 border-r-2 border-gray-700 transform ${
              isDrawerOpen ? 'rotate-135' : '-rotate-45'
            }`}
          />
        </button>

        {/* Contenedor flex para dividir la altura */}
        <div className="flex flex-col h-full">
          <div className="flex-[1]">
            <Drawer setTokens={setTokens} socket={socket} tokens={tokens} />
          </div>
          <div className="flex-[3]">
            <ChatWindow isOrganizer={true} />
          </div>
        </div>
      </div>
    </div>
  );
  };
  
  export default StagePage;