import React, { useState } from 'react';
import { ISerialWinnerPlayer, IToken } from '../common/interfaces';
import Card from './card';
import Lottie from 'lottie-react';
import serialWinnerAnimation from '../lottie-animations/serialWinnerAnimation.json'
import { usePlayer } from '../providers/PlayerProvider';

interface MyComponentProps {
  serialWinnerPlayer: ISerialWinnerPlayer;
  tokens: IToken[];
}
  
const SerialWinnerAnimation: React.FC<MyComponentProps> = ({ serialWinnerPlayer, tokens }) => {
  const playerContext = usePlayer();
  const [isVisible, setIsVisible] = useState(true);

  // Mapeamos tokens son los tokensId pero ignoramos drawnDate para que no aparezcan las fichas anotadas en el carton ganador del serial.
  serialWinnerPlayer.card.tokens = serialWinnerPlayer.card.tokenIds.map((tokenId) => {
    const foundToken = tokens.find((token) => token.id === tokenId)!
    foundToken.drawnDate = undefined;
    return foundToken;
  });

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black z-50">
      {/* Botón de cerrar */}
      <button
        onClick={() => setIsVisible(false)}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Cerrar
      </button>
      {/* Animación Lottie */}
      {serialWinnerPlayer.id === playerContext.player?.id &&
      <Lottie
      animationData={serialWinnerAnimation}
      loop={true}
      className="w-full h-full max-h-[30%] object-contain"
      />
      }

      {/* Texto centrado arriba de la animación */}
      <div className="text-center mt-12">
        <h1 className="text-white text-4xl font-bold">
          {serialWinnerPlayer.id === playerContext.player?.id ? "Tienes el Serial Ganador" : "No tuviste suerte"}  
        </h1>
        {serialWinnerPlayer.id !== playerContext.player?.id && <p className='bg-yellow-500'>{serialWinnerPlayer.name} tiene el serial ganador</p>}
        <h2 className="text-white text-3xl font-bold animate-growShrink">
          {serialWinnerPlayer.card.id}
        </h2>
      </div>
      <Card newCard={serialWinnerPlayer.card} showSerial={false}/>
      {serialWinnerPlayer.id === playerContext.player?.id && <p className="mt-2 text-white text-lg">Contacta al organizador...</p>}  
      
    </div>
  );
};

export default SerialWinnerAnimation;
