import { IToken } from "./interfaces";

// Precargar imágenes
export const preloadTokenImages = async (tokens: IToken[]) => {
    const tokenUrls = tokens.map((token) => '/tokens/' + token.name + '.png');
    const promises = tokenUrls.map((url) => {
      return new Promise<void>((resolve, reject) => {
        const img = new Image();
        img.src = url;
        img.onload = () => resolve();
        img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
      });
    });

    await Promise.all(promises);
};  
  