import React, { useState, useEffect, useRef } from 'react';
import { ILotteryCardPlayer, ISellCardError, ISellCards } from '../common/interfaces';
import { useNavigate } from 'react-router-dom';

type PlayerError = {
  name: string;
  email: string;
  phone: string;
};

interface PaginatedResponse {
  players: ILotteryCardPlayer[];
  totalPages: number;
}

interface MyComponentProps {
  lotteryId: number,
}


const Players: React.FC<MyComponentProps> = ({lotteryId}) => {
  
  const [players, setPlayers] = useState<ILotteryCardPlayer[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [errors, setErrors] = useState<PlayerError[]>([]);

  const [errorsFromServer, setErrorsFromServer] = useState<ISellCardError[][]>([]);
  const [successes, setSuccesses] = useState<boolean[]>([]);

  const [inputValues, setInputValues] = useState<{ [key: number]: string }>({});

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLotteries = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/lotteries/${lotteryId}/soldCards?page=${currentPage}`,
          {credentials: 'include'}
        );
        const data: PaginatedResponse = await response.json();
        setPlayers(data.players);
        setErrors(() => {
          return Array(data.players.length).fill({
            name: '',
            email: '',
            phone: '',
            cardIds: ''
          });
        })

        setTotalPages(data.totalPages);
      } catch (error) {
        console.error('Error fetching lotteries:', error);
      }
    };

    fetchLotteries();
  }, [currentPage]);

  const handleInputChange = (playerIndex: number, value: string) => {
    setInputValues((prevValues) => ({
      ...prevValues,
      [playerIndex]: value,
    }));
  };

  const handleChange = (playerIndex: number, field: string, value: string) => {
    setPlayers((prevPlayers) => {
      const updatedPlayer = [...prevPlayers];
      updatedPlayer[playerIndex] = {...updatedPlayer[playerIndex], [field]: value};
      return updatedPlayer;
    });
    validateField(playerIndex, field, value);
    setSuccesses((prevSuccesses) => {
      const updatedSuccesses = [...prevSuccesses];
      updatedSuccesses[playerIndex] = false;
      return updatedSuccesses;
    });
  };

  const handleAddCardId = (playerIndex: number, newCardId: string) => {
    const newId = parseInt(newCardId.trim(), 10);
  
    if (!isNaN(newId)) {
      setPlayers((prevPlayers) => {
        const updatedPlayers = [...prevPlayers];

        if (!updatedPlayers[playerIndex].cardIds?.includes(newId)) {
          updatedPlayers[playerIndex].cardIds = [
            ...updatedPlayers[playerIndex].cardIds!,
            newId,
          ];
        }
        return updatedPlayers;
      });
      setInputValues((prev) => ({ ...prev, [playerIndex]: '' }));
    }
  };

  const handleRemoveCardId = (playerIndex: number, cardId: number) => {
    setPlayers((prevPlayers) => {
      const updatedPlayers = [...prevPlayers];
      updatedPlayers[playerIndex].cardIds = updatedPlayers[playerIndex].cardIds?.filter(id => id !== cardId);
      return updatedPlayers;
    });

  };

  const validateField = (playerIndex: number, field: string, value: string) => {
    let errorMessage = '';

    switch (field) {
      case 'name':
        if (value.trim().length < 4) {
          errorMessage = 'El nombre debe tener al menos 4 caracteres.';
        }
        break;
      case 'email':
        if (value !== '' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          errorMessage = 'El correo electrónico no es válido.';
        }
        break;
      case 'phone':
        if (value !== '' && !/^\d{10}$/.test(value)) {
          errorMessage = 'El número de teléfono debe tener 10 dígitos.';
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[playerIndex] = {
        ...(prevErrors[playerIndex]), // Asegúrate de no tener undefined aquí
        [field]: errorMessage,
      };
      return updatedErrors;
    });

  };

  const handleSubmit = async (e: React.FormEvent, playerIndex: number) => {
    e.preventDefault();
    setErrorsFromServer([]);

    const isValid =  Object.values(errors[playerIndex]).every((error) => error === '');
    
    if(isValid) {
      const player = players[playerIndex];
      const formData = {
        player: {
          name: player.name,
          email: player.email,
          phone: player.phone
        },
        cardIds: player.cardIds ? player.cardIds : []
      };
  
        try {
          const response = await fetch(process.env.REACT_APP_API_URL + `/lotteries/players/${player?.id}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
            credentials: 'include'
          });
  
          if (response.ok) {
            setSuccesses((prevSuccesses) => {
              const updatedSuccesses = [...prevSuccesses];
              updatedSuccesses[playerIndex] = true;
              return updatedSuccesses;
            });
          } else {
            const { message: errors }: { message: ISellCardError[] } = await response.json();
            setErrorsFromServer((prevErrors) => {
              const updatedErrors = [...prevErrors];
              updatedErrors[playerIndex] = errors;
              return updatedErrors;
            });
  
          }
        } catch (error) {
          console.error('Error:', error);  
        }     
    }
   
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-center mb-6">Jugadores</h1>
      <div className="space-y-4">
       {players.map((player, playerIndex) => (
        <form key={player.id} onSubmit={(e) => handleSubmit(e, playerIndex)} className="p-4 space-y-4 bg-gray-100 rounded-md">

          <div className="space-y-4">
            <div className="border rounded p-3">
              <label htmlFor="cartonIds" className="block text-sm font-medium text-gray-700 mb-2">
                Anadir Cartones
              </label>
              <input
                type="text"
                id="cartonIds"
                value={inputValues[playerIndex] || ''}
                onChange={(e) => handleInputChange(playerIndex, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleAddCardId(playerIndex, inputValues[playerIndex]);
                  }
                }}
                maxLength={5}
                className="w-full border border-gray-300 rounded px-3 py-2"
              />
              <button
              type="button"
              onClick={() => {
                handleAddCardId(playerIndex, inputValues[playerIndex]);
              }}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 mt-4"
              >Anadir</button>
            </div>

            <div className="flex flex-wrap gap-2">
              {player.cardIds?.map((cardId) => (
                <span
                  key={cardId}
                  className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full"
                >
                  {cardId}
                  <button
                    type='button'
                    onClick={() => handleRemoveCardId(playerIndex, cardId)}
                    className="ml-2 bg-blue-200 hover:bg-blue-300 rounded-full w-5 h-5 flex items-center justify-center text-blue-800"
                  >
                    &times;
                  </button>
                </span>
              ))}
            </div>
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Nombre
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={player.name}
                onChange={(e) =>
                  handleChange(playerIndex, 'name', e.target.value)
                }
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              {errors[playerIndex]?.name && <p className="text-red-500 text-sm">{errors[playerIndex]?.name}</p>}
            </div>

            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Correo Electrónico
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={player.email}
                onChange={(e) =>
                  handleChange(playerIndex, 'email', e.target.value)
                }
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              {errors[playerIndex]?.email && <p className="text-red-500 text-sm">{errors[playerIndex]?.email}</p>}
            </div>

            <div>
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Teléfono
              </label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={player.phone}
                onChange={(e) =>
                  handleChange(playerIndex, 'phone', e.target.value)
                }
                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm"
              />
              {errors[playerIndex]?.phone && <p className="text-red-500 text-sm">{errors[playerIndex]?.phone}</p>}
            </div>
            <p>Pase: {player.pass}</p>  
            {errorsFromServer[playerIndex]?.map((error, index) => (
              <p key={index} className="text-red-500 text-sm">Carton Serial {error.cardId} {error.message}</p>
            ))}
            {successes[playerIndex] && <p className="text-green-500 text-sm">Jugador actualizado exitosamente.</p>}
          </div>
          <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Actualizar
            </button>    
</form>
        ))}
      </div>

      <div className="flex justify-center items-center space-x-2 mt-6">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50"
        >
          Anterior
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 rounded ${
              currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400'
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default Players;
