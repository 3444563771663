import React, { useState, useEffect } from 'react';
import { ICard, ILottery, IToken } from '../common/interfaces';
import { useParams } from 'react-router-dom';
import Card from '../components/card';
import Loading from '../components/loading';

interface PaginatedResponse {
  cards: ICard[];
  totalPages: number;
}

const ShowcasePage: React.FC = () => {
  const { lotteryId } = useParams();
  const [tokens, setTokens] = useState<IToken[]>([]); // Estado para los tokens
  const [lottery, setLottery] = useState<ILottery>();
  const [cards, setCards] = useState<ICard[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const fetchAllTokens = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "/tokens/all");
        if (!response.ok) {
          throw new Error("Error en la solicitud");
        }
        const tokens: IToken[] = await response.json();
        setTokens(tokens); // Guardamos los tokens en el estado
      } catch (error) {
        console.error("Error fetching tokens:", error);
      }
    };  

    fetchAllTokens();
  }, []);

  useEffect(() => {

    const fetchLottery = async () => {
      const response = await fetch(process.env.REACT_APP_API_URL + "/lotteries/" + lotteryId);
      if (!response.ok) throw new Error("Enter failed");
  
      const lottery: ILottery = await response.json();
      setLottery(lottery);
    }

    fetchLottery();

  }, [lotteryId]);  

  // Cargar las loterías cuando `currentPage` cambie
  useEffect(() => {
    if (tokens.length === 0 && !lottery) return; // Esperar hasta que los tokens estén cargados
    
    const fetchCards = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + `/lotteries/${lotteryId}/availableCards?page=${currentPage}`
        );
        if (!response.ok) {
          throw new Error("Error en la solicitud de loterías");
        }
        const data: PaginatedResponse = await response.json();

        // Mapear `card.tokenIds` con los tokens cargados
        setCards(() => (
          data.cards.map((card) => {
            card.tokens = card.tokenIds.map((tokenId) =>
              tokens.find((token) => token.id === tokenId)!
            );
            return card;
          })
        ));

        setTotalPages(data.totalPages);
      } catch (error) {
        console.error("Error fetching lotteries:", error);
      }
    };

    fetchCards();
    setLoading(false);

  }, [currentPage, tokens, lotteryId]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if(loading) {
    return <Loading/>
  }

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-2xl font-bold text-center mb-6">Cartones Disponibles</h1>
      <div className="space-y-4">
        {cards.map((card) => (
          <Card key={card.id} newCard={card} tokens={card.tokens} showSerial={true}/>
        ))}
      </div>

      <div className="flex justify-center items-center space-x-2 mt-6">
        <button
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50"
        >
          Anterior
        </button>
        {[...Array(totalPages)].map((_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-4 py-2 rounded ${
              currentPage === index + 1
                ? 'bg-blue-500 text-white'
                : 'bg-gray-300 hover:bg-gray-400'
            }`}
          >
            {index + 1}
          </button>
        ))}
        <button
          disabled={currentPage === totalPages}
          onClick={() => handlePageChange(currentPage + 1)}
          className="px-4 py-2 bg-gray-300 hover:bg-gray-400 rounded disabled:opacity-50"
        >
          Siguiente
        </button>
      </div>
    </div>
  );
};

export default ShowcasePage;
