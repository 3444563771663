import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_SOCKET_SERVER, { 
  autoConnect: false, 
  withCredentials: true 
});

socket.on('connect', () => {
  console.log(`Conectado al servidor.`);
});

socket.on('disconnect', () => {
  console.log(`Desconectado del servidor.`);
});

socket.on('connect_error', (error) => {
  console.error(`Error al conectar al servidor:`, error);
});

export default socket;