import React from 'react';
import {  useNavigate, useParams } from 'react-router-dom';
import CartonIdInput from '../components/sell_card';
import Players from '../components/players';

const SellCardsPage: React.FC = () => {
  const { lotteryId } = useParams();
  const navigate = useNavigate();
  if(lotteryId) {
    return (
      <div className="p-6 bg-gray-100 min-h-screen">
        <h1 className="text-2xl font-bold text-center mb-6">Venta de Cartones</h1>
        <div className="space-y-4">
          <button type="button"onClick={() => navigate('/organizer/lotteries')} className="text-green-500 hover:text-green-700 font-bold">Atras</button>
          <CartonIdInput lotteryId={+lotteryId}/>
          <Players lotteryId={+lotteryId}/>
        </div>
      </div>
    );
  } else {
    return <div>Cargando...</div>;
  }

};

export default SellCardsPage;
