import React, { useEffect, useState } from "react";
import { useOrganizer } from "../providers/OrganizerProvider";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const OrganizerLogin: React.FC = () => {
  const organizerContext = useOrganizer();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if(Cookies.get('connect.sid')) {
      navigate('../lotteries');
    }
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    organizerContext.login(email, password);
  };
  
return (
<form onSubmit={handleSubmit} className="max-w-sm mx-auto p-6 bg-white rounded-lg shadow-lg">
  <h2 className="text-2xl font-bold text-center mb-6">Iniciar sesión</h2>

  {/* Correo electrónico */}
  <div className="mb-4">
    <label htmlFor="email" className="block text-sm font-semibold text-gray-700 mb-2">Correo electrónico</label>
    <input
      id="email"
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
  </div>

  {/* Contraseña */}
  <div className="mb-6">
    <label htmlFor="password" className="block text-sm font-semibold text-gray-700 mb-2">Contraseña</label>
    <input
      id="password"
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required
    />
  </div>

  {/* Botón de inicio de sesión */}
  <button type="submit" className="w-full py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500">
    Iniciar sesión
  </button>
</form>
)


};

export default OrganizerLogin;
