import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import PlayerPage from './pages/player';
import StagePage from './pages/stage';
import LotteryEnter from './pages/player_enter';
import { OrganizerProvider } from './providers/OrganizerProvider';
import OrganizerLogin from './pages/organizer_login';
import CreateLotteryPage from './pages/create-lottery';
import LotteriesPage from './pages/lotteries';
import SellCardsPage from './pages/sell-cards';
import { PlayerProvider } from './providers/PlayerProvider';
import ShowcasePage from './pages/showcase';
import Auth from './components/Auth';

function App() {
  return (
    <Router>
          <Routes>
            {/* Rutas del organizador */}
            <Route
              path="/organizer/*"
              element={
                <OrganizerProvider>
                    <Routes>
                      <Route path="login" element={<OrganizerLogin />} />
                      <Route path="/" element={<Auth role={'organizer'} />}>
                        <Route path="lotteries" element={<LotteriesPage />} />
                        <Route path="lotteries/create" element={<CreateLotteryPage />} />
                        <Route path="lottery/:lotteryId/stage" element={<StagePage />} />
                        <Route path="lottery/:lotteryId/sellcards" element={<SellCardsPage />} />
                      </Route>
                    </Routes>
                </OrganizerProvider>
              }
            />

            {/* Rutas del jugador */}
            <Route
              path="/player/*"
              element={
                <PlayerProvider>  
                  <Routes>
                    <Route path="enter" element={<LotteryEnter />} />
                    <Route path="/" element={<Auth role={'player'} />}>
                      <Route path="lotteries/table" element={<PlayerPage />} />
                    </Route>
                  </Routes>
                </PlayerProvider>
              }
            />

            {/* Rutas pubicas */}
            <Route
              path="/lotteries/:lotteryId"
              element={<ShowcasePage/>}
            />            
          </Routes>
    </Router>
  );
}

export default App;
