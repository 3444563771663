import React, { useEffect } from "react";
import { IToken } from "../common/interfaces";

interface MyComponentProps {
  tokens: IToken[],
}

const MasterCard: React.FC<MyComponentProps> = ({tokens}) => {

  tokens.sort((a, b) => {
    // Si ambos tienen `drawnDate`, ordena por fecha de forma descendente
    if (a.drawnDate && b.drawnDate) {
      return new Date(b.drawnDate).getTime() - new Date(a.drawnDate).getTime(); 
    }

    // Si uno de los tokens no tiene `drawnDate`, colócalo al final
    if (!a.drawnDate && b.drawnDate) {
      return 1; // `a` va al final
    }
    if (a.drawnDate && !b.drawnDate) {
      return -1; // `b` va al final
    }
    
    return 0; // Si ambos son `null` o `undefined`, no cambia el orden
  });
  
  return (
<div className="grid grid-cols-5 w-full sm:grid-cols-7 md:grid-cols-10 gap-1 sm:gap-2 p-1 sm:p-4 bg-gray-200 rounded-lg shadow-md border-gray-200">
  {Array.from({ length: Math.ceil(tokens.length / 5) }, (_, rowIndex) =>
    tokens.slice(rowIndex * 5, rowIndex * 5 + 5).map((token) => (
      <div key={token.id} className="relative flex items-center justify-center border border-gray-300 rounded-lg bg-white p-2">
        <img
          src={`/tokens/${token.name}.png`}
          alt={`Ficha ${token.name}`}
          className="w-14"
        />
        {token.drawnDate && (
          <img
            src={`/beans/corn.png`}
            className="absolute w-6"
            alt="annotator"
          />
        )}
      </div>
    ))
  )}
</div>

  );
};

export default MasterCard;