import React, { createContext, useContext, ReactNode, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ILotteryCardPlayer } from "../common/interfaces";

interface PlayerContextType {
  player?: ILotteryCardPlayer;
  enter: (pass: string) => void;
  logout: () => void;
}

const PlayerContext = createContext<PlayerContextType | undefined>(undefined);

export const PlayerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [player, setPlayer] = useState<ILotteryCardPlayer | undefined>(() => {
    const player = localStorage.getItem("player");
    return player ? JSON.parse(player) : undefined;
  });
  const navigate = useNavigate();
  
  const enter = async (pass: string) => {
    const response = await fetch(process.env.REACT_APP_API_URL + "/auth/player/enter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          pass: pass
        }),
        credentials: "include",
      });

      if (!response.ok) throw new Error();

      const player: ILotteryCardPlayer = await response.json();
      setPlayer(() => {
        localStorage.setItem("player", JSON.stringify(player));
        return player;
      });
      navigate(`/player/lotteries/table`, {replace: true});

  };
  
  const logout = () => {
    setPlayer(undefined);
  };

  return (
    <PlayerContext.Provider value={{ player, enter, logout }}>
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayer = () => {
  const context = useContext(PlayerContext);
  if (!context) {
    throw new Error("usePlayer debe ser usado dentro de un PlayerProvider");
  }
  return context;
};
