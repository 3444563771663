import React from 'react';
import { IDrawnTokenEvent, IRound, IToken } from '../common/interfaces';
import Card from './card';

interface MyComponentProps {
    lastDrawnTokenEvent: IDrawnTokenEvent;
    currentRound: IRound;
    tokens: IToken[];
    nextRound: () => void;
    setIsCardWinner: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const LooserAnimation: React.FC<MyComponentProps> = ({ lastDrawnTokenEvent, tokens, currentRound, nextRound, setIsCardWinner }) => {

  lastDrawnTokenEvent.winners.map((winner) => {
    winner.cards = winner.cards.map((card) => {
      card.tokens = card.tokenIds.map((tokenId) => (tokens.find((token) => token.id === tokenId)!));
      return card;
    });
  });

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black z-50">
      {/* Botón de cerrar */}
      <button
        onClick={() => {nextRound(); setIsCardWinner(undefined)}}
        className="absolute top-4 right-4 bg-red-500 text-white px-4 py-2 rounded-lg z-10"
      >
        Cerrar
      </button>

      <div className="text-center mt-20">
        <h1 className="text-white text-4xl font-bold">
          No tuviste suerte...
        </h1>
        <h2 className="text-white text-3xl font-bold">
          {lastDrawnTokenEvent.winners.length === 1 ? "El ganador es" : "Los ganadores son"}
        </h2>

        {/* Contenedor con scroll */}
        <div className="mt-4 overflow-y-auto rounded-lg">
          {lastDrawnTokenEvent.winners.map((winner) => (
            <div className="mb-4 p-2 border-b border-gray-600 last:border-none">
              <p className="text-white text-lg">{winner.name}</p>
              <Card
                key={winner.cards[0].id}
                newCard={winner.cards[0]}
                showSerial={true}
                tokens={tokens}
                winningCombination={currentRound.winningCombination.combination}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LooserAnimation;
